import React from "react";
import "../styles/Info.css";

const Info = () => {


    return (
        <div className="Info">

        </div>    
    )
}


export default Info;